<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import slide from "@/components/ui/baseComponents/slide.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import horizontalItemCard from "@/components/ui/baseComponents/horizontalItemCard.vue";
import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";
import Accordion from "@/components/ui/baseComponents/accordion.vue";
import Swal from "sweetalert2";

const store = useStore();

const deleteAction = async (event) => {
  await store.dispatch("MAgeneralModule/deleteInductionScheduled", {
    induction_schedule: event.id,
  });
  await store.dispatch("MAgeneralModule/fetchInductionsByPractice", {
    practice: store.getters["MAgeneralModule/getCurrentPractice"],
  });
};

const deleteInductionPolicy = async (event) => {
  console.log("check", event);
  await store.dispatch("MAgeneralModule/deleteInductionPolicy", {
    induction_checklist: event.id,
  });
  FetchInductionPolicies();
  Swal.fire({
    title: "Done",
    text: "successfully deleted Policy",
    icon: "success",
  });
};
const dropdownOptions = [
  {
    type: "link",
    linkName: "ma-conduct-induction-meeting",
    paramName: "inductionId",
    label: "View",
  },
  {
    type: "action",
    label: "Delete",
    action: deleteAction,
  },
];
const showSinglePolicy = ref(false);
const ViewSinglePolicy = (data) => {
  store.dispatch("MAgeneralModule/fetchSingleInductionChecklist", {
    induction_checklist: data.id,
  });
  showSinglePolicy.value = true;
};

const singleChecklist = computed(() => {
  return store.getters["MAgeneralModule/getSingleInductionChecklist"];
});
const tabledropdownOptions = [
  {
    type: "action",
    label: "View",
    action: ViewSinglePolicy,
  },
  // {
  //   type: "link",
  //   linkName: "ma-edit-staff-induction-policies",
  //   paramName: "id",
  //   label: "Edit",
  // },
  {
    type: "action",
    label: "Delete",
    action: deleteInductionPolicy,
  },
];

const tableHeaders = [
  { title: "Start Date", prop: "created_at", id: "date" },
  { title: "Employee", prop: "user.profile", id: "fullname" },
  { title: "Induction Date", prop: "date", id: "induction" },
  { title: "Induction Form", prop: "", id: "name" },
  { title: "Status", prop: "is_completed", id: "status" },

  {
    title: "Actions",
    prop: "user",
    id: "actions",
  },
];

var activeTab = "new_hires";
const tabs = [
  { title: "New Hires", val: "new_hires" },
  {
    title: "Induction Records",
    val: "induction_records",
  },
];

const policiesTableHeader = [
  { title: "Checklist Name", prop: "name", id: "name" },
  { title: "Created At", prop: "created_at", id: "date" },
  { title: "Role", prop: "role.name", id: "role" },
  {
    title: "Actions",
    prop: "user",
    id: "actions",
  },
];

const loading = computed(() => {
  return store.getters["MAgeneralModule/getLoding"];
});
const showDropDown = ref(false);
const inductions = computed(() => {
  return store.getters["MAgeneralModule/getPracticeInductions"].data?.filter(
    (item) => !item.is_completed
  );
});
const allInductions = computed(() => {
  return store.getters["MAgeneralModule/getPracticeInductions"]?.data;
});
const inductionPolicies = computed(() => {
  return store.getters["MAgeneralModule/getInductionPolicies"]?.data;
});
const FetchInductionPolicies = async (page = null) => {
  await store.dispatch("MAgeneralModule/fetchInductionPolicies", {
    practice: store.getters["MAgeneralModule/getCurrentPractice"],
    ...(page && { page }),
  });
};
const FetchInductionScheduled = async (page = null) => {
  await store.dispatch("MAgeneralModule/fetchInductionsByPractice", {
    practice: store.getters["MAgeneralModule/getCurrentPractice"],
    ...(page && { page }),
  });
};
FetchInductionScheduled();
const totalInductionPolicies = computed(() => {
  return store.getters["MAgeneralModule/getTotalInductionPolicies"];
});
const totalInductionScheduled = computed(() => {
  return store.getters["MAgeneralModule/getTotalInductionScheduled"];
});

const showModal = ref(false);
const showAllInductionPolicies = async () => {
  showModal.value = true;
  FetchInductionPolicies();
};
const submit = () => {
  showModal.value = false;
};
const policyInformationList = computed(() => {
  return [
    { title: "Checklist Name", value: singleChecklist.value.name },
    {
      title: "Checklist Description",
      value: singleChecklist.value.description,
    },
    { title: "Role", value: singleChecklist.value.role.name },
    { title: "Practice", value: singleChecklist.value.practice.practice_name },
  ];
});
</script>
<template>
  <div class="w-full h-full flex flex-col">
    <div class="flex justify-center my-5">
      <div class="flex flex-col justify-between">
        <router-link :to="{ name: 'ma-create-staff-induction' }">
          <horizontal-item-card :icon="'add'" :title="'New Induction'">
          </horizontal-item-card>
        </router-link>
        <horizontal-item-card
          @click="showAllInductionPolicies"
          :icon="'policy'"
          :title="'Induction Checklists'"
        >
        </horizontal-item-card>
      </div>
      <div class="w-3/4 bg-teal rounded-xl mb-2 px-5">
        <h3 class="text-lg text-white text-center pt-2">Upcoming Inductions</h3>
        <vueper-slides
          v-if="!loading || inductions"
          fixed-height="270px"
          class="no-shadow"
          :visible-slides="3"
          :dragging-distance="20"
          :gap="-2"
          :bullets-outside="false"
          :arrows-outside="false"
        >
          <vueper-slide
            v-for="interview in inductions?.slice(0, 4)"
            :key="interview.id"
          >
            <template v-slot:content>
              <slide
                :icon="'meeting_room'"
                :title="interview.user.email"
                :route="{
                  name: 'ma-conduct-induction-meeting',
                  params: { inductionId: interview.id },
                }"
              />
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>

    <div class="w-11/12 mx-auto text-grey my-2 flex flex-col flex-grow">
      <p class="text-left font-medium text-xl mb-3">
        Employee Induction Records
      </p>
      <div class="mb-4 border-b w-11/12 mt-10">
        <ul class="flex flex-wrap -mb-px">
          <li class="mr-2" v-for="item in tabs" :key="item.value">
            <button
              class="inline-block py-4 px-4 text-sm font-medium text-center rounded-t-lg border-b-2 border-transparent hover:text-teal hover:border-teal"
              :class="
                activeTab === item.value ? 'text-teal border-teal' : 'text-grey'
              "
              @click="activeTab = item.value"
            >
              {{ item.title }}
            </button>
          </li>
        </ul>
      </div>
      <template
        v-if="!loading || (inductions && activeTab === 'induction_records')"
      >
        <el-table
          header-row-class-name="bg-gray-200"
          header-cell-class-name="text-teal py-5 "
          row-class-name="border-t"
          class="w-full rounded-xl overflow-hidden bg-gray-100 flex flex-grow"
          :data="allInductions"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column
            v-for="item in tableHeaders"
            :key="item.index"
            :prop="item.prop"
            :label="item.title"
            sortable
          >
            <template #default="scope">
              <div class="" v-if="item.id === 'fullname'">
                {{
                  scope.row.user.profile.first_name +
                  " " +
                  scope.row.user.profile.last_name
                }}
              </div>
              <div class="" v-if="item.id === 'date'">
                {{ new Date(scope.row.created_at).toLocaleDateString("en-GB") }}
              </div>
              <div class="" v-if="item.id === 'induction'">
                {{ new Date(scope.row.date).toLocaleDateString("en-GB") }}
              </div>
              <div class="" v-if="item.id === 'status'">
                {{ scope.row.is_completed ? "Completed" : "Not Completed" }}
              </div>
              <div class="" v-if="item.id === 'actions'">
                <table-options-dropdown
                  :show="showDropDown"
                  :id="scope.row.id"
                  :options="dropdownOptions"
                  :dynamicParam="scope.row.id"
                >
                  <span class="material-icons"> more_horiz </span>
                </table-options-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center my-5">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalInductionScheduled"
            @current-change="
              (e) => {
                FetchInductionScheduled(e);
              }
            "
          >
          </el-pagination>
        </div>
      </template>
      <template v-else>
        <table-skeleton />
      </template>
    </div>
    <base-modal v-model:show="showModal" :showCancel="false" @confirm="submit">
      <template #title>Induction Checklists</template>
      <template #content>
        <div class="flex justify-between">
          <div
            class="flex items-center text-teal ml-10 cursor-pointer"
            @click="showSinglePolicy = false"
          >
            <template v-if="showSinglePolicy">
              <span class="material-icons-outlined"> arrow_back </span>
              go Back
            </template>
          </div>
          <router-link :to="{ name: 'ma-staff-induction-policies' }">
            <base-button class="flex items-center">
              <span class="material-icons mr-1 cursor-pointer"> add </span>New
              Checklist</base-button
            >
          </router-link>
        </div>
        <div
          class="w-11/12 mx-auto text-center text-grey my-2 flex flex-col flex-grow"
        >
          <template v-if="!loading">
            <template v-if="!showSinglePolicy">
              <el-table
                header-row-class-name="bg-gray-200"
                header-cell-class-name="text-teal py-5"
                :cell-style="{ padding: '10px' }"
                row-class-name="border-t"
                class="w-full rounded-xl overflow-hidden bg-gray-100 flex flex-grow"
                :data="inductionPolicies"
                :default-sort="{ prop: 'date', order: 'descending' }"
                style="width: 100%"
              >
                <el-table-column
                  v-for="item in policiesTableHeader"
                  :key="item.index"
                  :prop="item.prop"
                  :label="item.title"
                  :sortable="item.id != 'actions' ? true : false"
                >
                  <template #default="scope">
                    <div class="" v-if="item.id === 'date'">
                      {{
                        new Date(scope.row.created_at).toLocaleDateString(
                          "en-GB"
                        )
                      }}
                    </div>
                    <div class="" v-if="item.id === 'actions'">
                      <table-options-dropdown
                        :id="scope.row.id"
                        :options="tabledropdownOptions"
                        :dynamicParam="scope.row.id"
                      >
                        <span class="material-icons"> more_horiz </span>
                      </table-options-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="flex justify-center my-5">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="totalInductionPolicies"
                  @current-change="
                    (e) => {
                      FetchInductionPolicies(e);
                    }
                  "
                >
                </el-pagination>
              </div>
            </template>
            <template v-else>
              <div>
                <div class="w-auto">
                  <div
                    v-for="item in policyInformationList"
                    :key="item.index"
                    class="flex justify-start"
                  >
                    <div class="w-1/4 text-left mb-3">
                      <p>
                        <b>{{ item.title }}</b>
                      </p>
                    </div>

                    <div class="w-1/2">
                      <p class="text-left break-all">{{ item.value }}</p>
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <Accordion
                    classes="my-1"
                    v-for="item in singleChecklist.induction_questions"
                    :key="item.index"
                    :isInner="true"
                    :title="item.question"
                  >
                    <p class="text-left">question:{{ item.question }}</p>
                  </Accordion>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <table-skeleton />
          </template>
        </div>
      </template>
      <template #successButton>Close</template>
    </base-modal>
  </div>
</template>